import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

const loader = () => {
  return (
    <div className='centered'>
      <Spinner animation='border' role='status' />
    </div>
  )
}

export default loader
