import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import Loadable from 'react-loadable'

import Loader from './loader'
import Aux from './_aux'
import ScrollToTop from './scrollToTop'
import routes from '../routes/public-routes'
import { ToastContainer } from 'react-toastify'

const AdminLayout = Loadable({
  loader: () => import('./AdminLayout'),
  loading: Loader // QUITAR SI DA FALLO DE CARGA O SE MUESTRAN MAS DE 1
})

const App = () => {
  const menu = routes.map((route, index) => {
    return route.component ? (
      <Route key={index} path={route.path} exact={route.exact} name={route.name} render={props => <route.component {...props} />} />
    ) : null
  })

  return (
    <Aux>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Switch>
            {menu}
            <Route path='/' component={AdminLayout} />
          </Switch>
          <ToastContainer />
        </Suspense>
      </ScrollToTop>
    </Aux>
  )
}

export default App
